import React, {Fragment} from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {walletIcon, whiteLogo} from "../components/Images";
import {Link as LinkR} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";

const Sidebar = () => (
    <Fragment>
        <ListItemButton className="flex-column justify-content-center mt-3">
            <LinkR to="/dashboard">
                <ListItemIcon>
                    <img src={whiteLogo} alt='logo'/>
                </ListItemIcon>
            </LinkR>
            <ListItemText className="text-white" primary="Dashboard"/>
        </ListItemButton>

        <ListItemButton className="flex-column align-items-center mt-3">
            <LinkR to="/wallet">
                <ListItemIcon>
                    <img src={walletIcon} alt="wallet.png"/>
                </ListItemIcon>
            </LinkR>
            <ListItemText className="text-white" primary="Wallet"/>
        </ListItemButton>
        {/*<ListItemButton className="justify-content-center mt-3">
            <LinkR to={"/userdetails"}>
                <ListItemIcon>
                    <img src={userIcon} alt="Users.png"/>
                </ListItemIcon>
            </LinkR>
        </ListItemButton>*/}
        {/*<ListItemButton className="justify-content-center mt-3">
            <ListItemIcon>
                <img src={userSearchIcon} alt="userSearchIcon.png"/>
            </ListItemIcon>
        </ListItemButton>*/}
    </Fragment>
);

export default Sidebar
