import React, {useEffect, useState} from 'react'
import {Box, Button, Grid, Typography} from '@mui/material';
import apis from 'services';
import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "components/admin/AdminSidebar";
import {DataGrid} from "@mui/x-data-grid";
import StoresTable from "components/datagrid/StoresTable";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Toolbar from "../../components/@material-extend/CustomToolbar";
import ImagePreview from "../../components/modals/ImagePreview";

const Stores = () => {
    const [stores, setStores] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [imageModal, setImageModal] = useState(false);
    const [image, setImage] = useState('');
    const navigate = useNavigate()

    const closeImageModal = (image) => {
        console.log(image, '#####');
        if(image)
            setImage(image);
        setImageModal(prevState => !prevState);

    }

    const fetchAllStores = async () => {
        const res = await apis.getAllStores();
        setStores(res?.data?.stores)

    };

    const handleDisableStore = async (id) => {
        const res = await apis.disableStore(id);
        if (res.data.status) {
            toast.success(res.data.message);
            await fetchAllStores()
        }
    }
    const handleEnableStore = async (id) => {
        const res = await apis.enableStore(id);
        if (res.data.status) {
            toast.success(res.data.message);
            await fetchAllStores()
        }
    };
    const handleDeleteStore = async (id) => {
        const formattedId = typeof id === 'string' ? [id] : id;
        const res = await apis.deleteStores(formattedId);
        if (res?.data?.status) {
            toast.success(res.data?.message);
            setSelectionModel([])
            await fetchAllStores();
        } else
            toast.warning(res.data?.message)
    }


    useEffect(() => {
        (async () => {
            await fetchAllStores()
        })()
    }, []);

    const methods = {handleEnableStore, handleDisableStore, navigate,handleDeleteStore}
    const {rows, columns} = StoresTable(stores, methods,closeImageModal);


    const DeleteStoreButton = ({rows}) => {
        const ids = rows.length === 0
            ? []
            : [...rows.map(record => record._id)];

        return (
            <Button variant='outlined' size='small' onClick={() => handleDeleteStore(ids)}>
                Delete Stores
            </Button>
        )
    }

    const AddStoreButton = () => {
        return (
            <Button variant='outlined'
                    py={2} size='small'
                    onClick={() => navigate('add')}
                    color='error'>
                Add Store
            </Button>
        )
    }

    const NewToolbar = () => (
        <Toolbar
            buttons={[<AddStoreButton/>, <DeleteStoreButton/>]}
        />
    );

    return (
        <>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <AdminSidebar/>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        // height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Box display="flex" justifyContent="center" sx={{backgroundColor: 'var(--primary)', padding: 1}}>
                        <Typography variant="h5" component="div" sx={{fontWeight: 'bold', color: 'white'}}>
                            List of Stores
                        </Typography>
                    </Box>
                    <Grid item container justifyContent='flex-end' sx={{height: '70vh', padding: '1em'}}>
                        <DataGrid checkboxSelection rows={rows} columns={columns}
                                  onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                                  selectionModel={selectionModel}
                                  components={{Toolbar: NewToolbar}}
                        />
                    </Grid>
                </Box>
            </Box>
            <ImagePreview status={imageModal} close={closeImageModal} image={image}/>
        </>
    )
}


export default Stores;
