import {useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import reduxApis from "../redux/apis";

const AdminLogin = () => {
    const [formData, setFormData] = useState({email: "", password: ""});
    const [loginBtn, setLoginBtn] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onChangeHandler = (e) => {
        const {id, value} = e.target;
        setFormData((prevState) => ({...prevState, [id]: value}));
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoginBtn(true);
            await dispatch(reduxApis.adminLogin(formData))

            setTimeout(() => {
                setLoginBtn(false);
                navigate("/admin/dashboard")
            }, 1000)
        } catch (error) {
            setLoginBtn(false);
            console.log('ADMIN LOGIN ERROR', error);
            const message = error.response.data.message;
            toast.error(message);
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h2 className="text-center text-uppercase my-5">Login Admin</h2>
                </Col>
            </Row>
            <Form onSubmit={onSubmitHandler}>
                <Row>
                    <Col md={4}/>

                    <Col md={4}>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                onChange={onChangeHandler}
                                value={formData.email}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={onChangeHandler}
                                value={formData.password}
                                required
                            />
                        </Form.Group>

                        <Button
                            variant="outline-danger"
                            className="w-100 mb-5"
                            type="submit"
                            disabled={loginBtn}
                        >
                            {" "}
                            Login{" "}

                        </Button>
                    </Col>
                </Row>
            </Form>
            {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
        </Container>
    );
};

export default AdminLogin;
