import React from "react";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {Delete, RemoveModerator, VerifiedUser, Visibility} from "@mui/icons-material";

/**
 * @param users
 * @param methods
 * @return {{columns: [{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},null], rows: *[]}}
 */
const UsersTable = (users = [], methods = {}) => {
    const {navigate, approveHandler, deleteHandler} = methods;
    const columns = [
        {field: 'id', headerName: 'Id', width: 150},
        {field: 'fullName', headerName: 'Full Name', width: 300},
        {field: 'email', headerName: 'Email', width: 150},
        {field: 'verified', headerName: 'Verified', width: 150},
        {field: 'createdAt', headerName: 'Created on', width: 150},
        {
            field: 'actions', headerName: 'Actions', width: 150, renderCell: (props) => {
                const isVerified = props.row.verified;
                const id = props.row._id;
                return (
                    <Grid container>
                        <Grid item>
                            <Tooltip title='View' arrow>
                                <IconButton size='small'
                                            onClick={() => navigate(`/user-details/${id}`)}><Visibility/></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            {isVerified
                                ?
                                (<Tooltip title='Disable Users' arrow>
                                    <IconButton size='small'
                                                onClick={() => approveHandler(id)}>
                                        <RemoveModerator/>
                                    </IconButton>
                                </Tooltip>)
                                : (<Tooltip title='Verify Users' arrow>
                                    <IconButton size='small' onClick={() => approveHandler(id)}>
                                        <VerifiedUser/></IconButton>
                                </Tooltip>)
                            }

                        </Grid>
                        <Grid item>
                            <Tooltip title='Delete' arrow>
                                <IconButton size='small'
                                            onClick={() => deleteHandler(id)}><Delete/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )
            }
        },
    ];

    const rows = users.length === 0
        ? []
        : [
            ...users.map((user, id) => ({
                id: id,
                fullName: user.name,
                email: user.email,
                verified: user.isVerified,
                createdAt: user.createdAt,
                _id: user._id
            }))
        ]


    return {rows, columns}
}

export default UsersTable