import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "components/admin/AdminSidebar";
import {Box, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import apis from "services";
import TransactionTable from "../../components/datagrid/TransactionTable";
import {DataGrid} from "@mui/x-data-grid";

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);

    const fetchTransactions = async () => {
        const {data} = await apis.getTransactions();
        if (data?.status) {
            setTransactions(data.transactions)
        }
    }

    useEffect(() => {
        (async () => {
            await fetchTransactions();
        })()
    }, []);


    const {rows, columns} = TransactionTable(transactions);


    return <Box sx={{display: "flex"}}>
        <CssBaseline/>
        <AdminSidebar/>
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                // height: "100vh",
                overflow: "auto",
            }}
        >
            <Box display="flex" justifyContent="center" sx={{backgroundColor: 'var(--primary)', padding: 1}}>
                <Typography variant="h5" component="div" sx={{fontWeight: 'bold', color: 'white'}}>
                    List of Transactions
                </Typography>
            </Box>
            <Grid item container justifyContent='flex-end' sx={{height: '70vh', padding: '1em'}}>
                <DataGrid rows={rows} columns={columns}/>
            </Grid>
        </Box>
    </Box>
}

export default Transactions
