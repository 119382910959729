import React, {useEffect, useState} from 'react'
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {useDispatch} from "react-redux";
import {getAllUser} from '../../redux/slices/adminSlice';
import apis from '../../services';
import {useParams} from 'react-router-dom';

const UserDetails = () => {

    const [dataUser, setData] = useState();
    const dispatch = useDispatch();

    const param = useParams();
    const userId = param.id;


    // useEffect(() => {
    //     dispatch(getAllUser());
    //     const result = dispatch(apis.getProfile(userId)).then((res) => {
    //         setData(res?.payload)
    //     })
    // }, []);

    return (
        <>
            <Grid item xs={7}>
                <Box display="flex" justifyContent="center" sx={{backgroundColor: 'red', padding: 1}}>
                    <Typography variant="h5" component="div" sx={{fontWeight: 'bold', color: 'white'}}> Details Of
                        User</Typography>
                </Box>
                <TableContainer component={Paper} style={{marginTop: 100, marginBottom: 100, marginRight: 130}}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Sno</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Last Name</TableCell>
                                <TableCell align="center">Phone</TableCell>
                                <TableCell align="center">Father Name</TableCell>
                                <TableCell align="center">Mother Name</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Passport</TableCell>
                                <TableCell align="center">PassportExpireDate</TableCell>
                                <TableCell align="center">BankAccount</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}} style={{padding: 10}}>
                                <TableCell align="center"> {1} </TableCell>
                                <TableCell align="center">{dataUser?.name}</TableCell>
                                <TableCell align="center">{dataUser?.type}</TableCell>
                                <TableCell align="center">{dataUser?.lastName}</TableCell>
                                <TableCell align="center">{dataUser?.phone}</TableCell>
                                <TableCell align="center">{dataUser?.fatherName}</TableCell>
                                <TableCell align="center">{dataUser?.motherName}</TableCell>
                                <TableCell align="center">{dataUser?.email}</TableCell>
                                <TableCell align="center">{dataUser?.passport}</TableCell>
                                <TableCell align="center">{dataUser?.passportExpireDate}</TableCell>
                                <TableCell align="center">{dataUser?.bankAccount}</TableCell>

                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>

    )
}

export default UserDetails