import React, {useState} from 'react'
import { Container, Row, Col, Accordion} from "react-bootstrap";
import imageUrl from "utils/imageUrl";
import { Sidebar } from "components";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'

const LandingPage = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] =useState(false);
  const {user} = useSelector((store) => store.user);
  
  const handleScroll = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className={`landing-page ${isOpen ? "open-sidebar" : ""}`}>
        <div className="scroll-icon-wrapper" onClick={handleScroll}>
          <img
            src={imageUrl("scroll-icon.png")}
            alt=""
            className="scroll-icon"
          />
        </div>
        <div className="main-section-wrapper position-relative">
          <div className="button-group">
              {user ? <></> : <> <button onClick={() => navigate('/login')}  className="simple-btn">
            LOGIN
            </button>
            <button onClick={() => navigate('/registration')}  className="lp-btn">
            SIGNUP
            </button>
         </>}                         
            </div>
          <section className="main-section">
            <div className="pt-md-5"></div>
            <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
            <Container className="h-100 main-container">
              <Row className="h-100">
                <Col
                  md={6}
                  className="d-flex flex-column justify-content-center main-section-text"
                >
                  <p className="section-title mb-0 dark-text text-uppercase text-start">
                    <span className="red-text">W</span>hy not think outside the{" "}
                    <span className="courgette text-capitalize">
                      Cup<span className="red-text courgette">?</span>
                    </span>
                  </p>
                  <p className="normal-p">
                    JTC opening the investment possibilities can assist you in
                    understanding how a coffee shop may be a suitable spot to
                    invest.
                  </p>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-center justify-content-center pe-5"
                >
                  <img
                    src={imageUrl("shop-crop.png")}
                    alt=""
                    className="lp-shop-crop"
                    width={"100%"}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <section className="custom-section grid-section normal-p">
          <p className="section-title text-uppercase dark-text">
            <span className="red-text section-title">I</span>nvest in franchises
          </p>
          <Container>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <div className="lp-invest-img">
                    <img src={imageUrl("signup-form.png")} alt="" />
                  </div>
                  <p className="">Sign up</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <div className="lp-invest-img">
                    <img src={imageUrl("find-store.png")} alt="" />
                  </div>
                  <p className="">Find your store to invest</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <div className="lp-invest-img">
                    <img src={imageUrl("fill-form.png")} alt="" />
                  </div>
                  <p className="">Fill and provide all necessary forms</p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <div className="lp-invest-img">
                    <img src={imageUrl("buy-stocks.png")} alt="" />
                  </div>
                  <p className="">Buy stocks</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <div className="lp-invest-img">
                    <img src={imageUrl("become-investor.png")} alt="" />
                  </div>
                  <p className="">Become a investor of javatimescafe</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="custom-section grid-section2 normal-p text-center grey-bg">
          <p className="section-title text-uppercase dark-text">
            <span className="red-text section-title">A </span>booming industry
          </p>
          <Container>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <p className="large-p">773,603</p>
                  <p className="">Franchise establishments</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <p className="large-p">7.6 million</p>
                  <p className="">Jobs franchising directly creates</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <p className="large-p">$787.5 billion</p>
                  <p className="">Economic output of franchise establishment</p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <p className="large-p">100+</p>
                  <p className="">Industries</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="lp-invest-i-wrapper">
                  <p className="large-p">4,300+</p>
                  <p className="">Franchise</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="custom-section accordion-section normal-p text-center">
          <p className="section-title text-uppercase dark-text">
            <span className="red-text section-title">H</span>ow will
            Javatimescafe <br /> work
            <span className="red-text section-title">?</span>
          </p>
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="target-img">
                  <img src={imageUrl("target.png")} alt="" />
                </div>
              </Col>
              <Col md={6} className="accordion-wrapper">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>01 Franchise Selection</Accordion.Header>
                    <Accordion.Body>
                      Our industry experts have vetted hundreds of franchise
                      brands that are regulated by the Federal Trade Commission.
                      We carefully select which brands to invest in based on
                      profitability, growth, manageability, recession
                      resistance, and executive leadership.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>02 Investment</Accordion.Header>
                    <Accordion.Body>
                      Our industry experts have vetted hundreds of franchise
                      brands that are regulated by the Federal Trade Commission.
                      We carefully select which brands to invest in based on
                      profitability, growth, manageability, recession
                      resistance, and executive leadership.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>03 Franchise Management</Accordion.Header>
                    <Accordion.Body>
                      Our industry experts have vetted hundreds of franchise
                      brands that are regulated by the Federal Trade Commission.
                      We carefully select which brands to invest in based on
                      profitability, growth, manageability, recession
                      resistance, and executive leadership.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>04 Liquidity</Accordion.Header>
                    <Accordion.Body>
                      Our industry experts have vetted hundreds of franchise
                      brands that are regulated by the Federal Trade Commission.
                      We carefully select which brands to invest in based on
                      profitability, growth, manageability, recession
                      resistance, and executive leadership.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="custom-section pure-section normal-p grey-bg tree-bg">
          <p className="section-title text-uppercase dark-text">
            <span className="red-text section-title pure-section-title">W</span>ant to invest in JTC?
          </p>
          <p className="normal-p pure-section-text">
            The option is yours. Whether you want to invest in a coffee shop or simply sip coffee while eating cookies, Java Times Caffe can give you both. 
          </p>
          <div className="d-flex justify-content-center mt-4 pt-2">
            <Link to="/investment">
            <button className="lp-btn">Invest Now</button>
            </Link>
          </div>
        </section>
        <section className="custom-section text-section normal-p grey-bg">
          <Container>
            <Row>
              <Col md={7}>
                <p className="section-title text-uppercase dark-text  text-start">
                  Thinking of passively investing in franchises but have
                  questions? Read our white paper to learn more.
                </p>
                <div className="text-lowercase  text-start">
                  <p className="normal-p">
                    ✓ The benefits of investing in franchises.
                  </p>
                  <p className="normal-p">
                    ✓ What returns investors can expect from franchise
                    investing.
                  </p>
                  <p className="normal-p">
                    ✓ How FranShares is FTC and SEC regulated.
                  </p>
                  <p className="normal-p">
                    ✓ How FranShares selects franchises to invest in.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Container></Container>
      </div>
    </>
  );
};

export default LandingPage;
