import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SmosOne, SmosTwo } from "../components/Images";
import { Fade } from "react-reveal";
function WeAreJava() {
  return (
    <>
      <section>
        <Container fluid>
          <Row className="col-without-padding">
            <Col lg={6} className="about-card-order-1">
              <Fade left>
                <div className="position-relative">
                  <img src={SmosOne} className="card-lg-smos-img" />
                </div>
              </Fade>
            </Col>
            <Col lg={6} className="about-card-order-2">
              <Fade right>
                <div className="card-lg">
                  <h3 className="card-title">A FLAVOR DREAM COME TRUE</h3>
                  <p>
                    Java Times Caffé has its origins more than 15 years ago, as
                    an organization dedicated to serving the coffee industry in
                    the United States of America.
                    <br />
                    <br />
                    In Mexico it saw its beginnings in 1999 in the city of
                    Torreón Coahuila as a coffee roasting company and
                    distributor of supplies and equipment for the industry, with
                    coverage for the entire Mexican Republic, always with the
                    support of sister companies in the United States. At the end
                    of the same year, the first Java Times Caffé coffee shop
                    opened, located in the Galerías Laguna shopping center in
                    the city of Torreón.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col lg={6} className="about-card-order-4">
              <Fade left>
                <div className="card-lg">
                  <h3 className="card-title">OUR MISSION</h3>

                  <p>
                    To be the leading franchise company in the specialty coffee
                    industry, providing the best quality products, the best
                    customer service, the best opportunities for our employees
                    and the best demonstration to one customer at a time.
                  </p>

                  <h3 className="card-title">OUR VALUES</h3>
                  <p>
                    Lorem Ipsum is simply a fictional text from the printing and
                    typesetting industry. Lorem Ipsum is simply a fictional text
                    from the printing and typesetting industry.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col lg={6} className="about-card-order-3">
              <Fade right>
                <img src={SmosTwo} />
              </Fade>
            </Col>
          </Row>
          <Container>
            <Fade right>
              <div className="row py-5">
                <div className="col-md-6 pure-card">
                  <h3 className="card-title">ABOUT JAVA TIMES</h3>

                  <p>
                    Caffe owners make money by investing at least 5,000 Mexican
                    pesos. We have it all: a full set up of Caffe or
                    money-earning options.
                  </p>
                </div>
                <div className="col-md-6 pure-card">
                  <h3 className="card-title">OUR STORY</h3>

                  <p>
                    The 30-year journey of Java Times Caffe began in the year
                    1999. Mr. Leite, the cafe's founder, had the foresight to
                    create such a profitable coffee shop, and he succeeded in
                    his mission by opening 300+ coffee stores around Mexico…
                  </p>
                </div>
                <div className="col-md-6 pure-card">
                  <h3 className="card-title">OUR STORY</h3>

                  <p>
                    The 30-year journey of Java Times Caffe began in the year
                    1999. Mr. Leite, the cafe's founder, had the foresight to
                    create such a profitable coffee shop, and he succeeded in
                    his mission by opening 300+ coffee stores around Mexico…
                  </p>
                </div>
                <div className="col-md-6 pure-card">
                  <h3 className="card-title">OUR MISSION</h3>

                  <p>
                    In 2020, Antonio Leite considered giving people a chance. He
                    is the one who proposes crowdsourcing or group investing
                    first. Thanks to the communal investment scheme, a person
                    can own a Piece of Java Times Caffe 300 coffee shop’s
                    Project in Mexico for as little as 5,000 MXN through Startup
                    Network System The operator.{" "}
                  </p>
                </div>
                <div className="col-md-6 pure-card">
                  <h3 className="card-title">OUR VISION</h3>

                  <p>
                    By constructing 300+ coffee shops, we want to operate our
                    system similarly to Robinhood, which will require 68,000 to
                    120,000 investors to deposit 5,000 MXN each investor will
                    obtain a little or big piece of the coffee shops now is the
                    moment purchased freshly shares through our recently
                    financed branch as price still low. We will provide our
                    clients with affordability and sustainability throughout the
                    next five years. We'll establish a supported branch. We have
                    the goal of bringing ease to every Mexican. This is a simple
                    way for all age groups to make money. with an eye on
                    creating AI systems in the future.
                  </p>
                </div>
              </div>
            </Fade>
          </Container>
        </Container>
      </section>
    </>
  );
}

export default WeAreJava;
