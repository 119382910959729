import {Box, Button, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {getProfile} from '../../redux/slices/adminSlice';
import apis from '../../services';
import {useNavigate} from 'react-router-dom';
import {DataGrid} from "@mui/x-data-grid";
import {Delete, RemoveModerator, VerifiedUser, Visibility} from "@mui/icons-material";
import Toolbar from "components/@material-extend/CustomToolbar";
import {toast} from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "../../components/admin/AdminSidebar";
import UsersTable from "../../components/datagrid/UsersTable";

export const Users = () => {

    const [users, setUsers] = useState([]);
    const navigate = useNavigate()


    /*Methods*/
    const fetchAllUsers = async () => {
        const res = await apis.getAllUser();
        if (res.data.status) setUsers(res.data.users)
    }

    const deleteHandler = async (id) => {
        const res = await apis.deleteUser(id);
        if (res.data.status) {
            console.log(res);
            toast.success(res.data.message)
            await fetchAllUsers();
        }
    }

    const approveHandler = async (id) => {
        const res = await apis.approveUser(id);
        if (res.data.status) {
            console.log(res);
            toast.success(res.data.message)
            await fetchAllUsers();
        }else
            toast.warn(res.data.message)

    }

    useEffect(() => {
        (async () => {
            await fetchAllUsers()
        })()
    }, []);

    const NewToolbar = () => (
        <Toolbar
            buttons={[
                <Button variant='outlined' py={2} size='small' onClick={() => navigate('add')} color='error'>
                    Add Users
                </Button>
            ]}
        />
    );

    const {rows, columns} = UsersTable(users, {navigate,approveHandler, deleteHandler})

    return (
        <>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <AdminSidebar/>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        // height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Box display="flex" justifyContent="center" sx={{backgroundColor: 'var(--primary)', padding: 1}}>
                        <Typography variant="h5" component="div" sx={{fontWeight: 'bold', color: 'white'}}>
                            List of Users
                        </Typography>
                    </Box>
                    <Grid item container justifyContent='flex-end' sx={{height: '70vh', padding: '1em'}}>
                        <DataGrid checkboxSelection rows={rows} columns={columns}
                                  components={{Toolbar: NewToolbar}}/>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Users
