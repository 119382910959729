import React from "react";
import { Link } from "react-router-dom";
import { NotFoundImg } from "../components/Images";
import { ArrowBackOutlined } from "@mui/icons-material";
const NotFound = () => {
  return (
    <div>
      {" "}
      <div className="not-found-image ">
        <img src={NotFoundImg} />
        <div className="button ">
          <Link to={"/"}>
            <ArrowBackOutlined />
            <span>Go to Home</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
