export const FORM_NAMES = {
    investor: "Investor Form",
    legalEntity: "Legal Entity Investor Form",
    jtc: "JTC Employee Form"
};

export const STATES = [
    "AGUASCALIENTES",
    "BAJA CALIFORNIA",
    "SOUTH",
    "CAMPECHE",
    "CHIAPAS",
    "CHIHUAHUA",
    "MEXICO CITY",
    "COAHUILA",
    "COLIMA",
    "DURANGO",
    "EDO. FROM MEXICO",
    "GUANAJUATO",
    "GUERRERO",
    "HIDALGO",
    "JALISCO",
    "MICHOACÁN",
    "MORELOS",
    "NAYARIT",
    "NUEVO LE`ÓN",
    "OAXACA",
    "PUEBLA",
    "QUERETARO",
    "QUINTANA ROO",
    "SAN LUIS POTOSI",
    "SINALOA",
    "SONORA",
    "TABASCO",
    "TAMAULIPAS",
    "TLAXCALA",
    "VERACRUZ",
    "YUCATÁN",
    "ZACATECAS",
]

export const USERS_TYPES = {
    normal: "normal",
    investor: 'investor',
    legalEntity: 'legal entity',
    jtc: 'jtc'
}


export const  STATIC_STORE_DATA = {
    totalShares: 100,
    sharePerPrice: 20
}
