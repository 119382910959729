import React from "react";
import {BrowserRouter as Router, Route, Routes, useLocation,} from "react-router-dom";
import {
    AdminLogin,
    Blog,
    Contact,
    Home,
    Investment,
    InvestNow,
    InvestorForm,
    LegalEntityInvestorForm,
    Login,
    Menu,
    NotFound,
    Offices,
    Race,
    Registration,
    Shop,
    WeAreJava
} from "./screens";

// import { initialState, reducer } from '../src/reducer/UseReducer';
import {CheckoutForm, Footer, Header, ProtectedRoute, ScrollToTop} from "components";
import DashboardContent from "screens/UserDashboard/Dashboard";
// import UserDetail from "./screens/UserDashboard/UserDetail";

import UserDetails from "./components/admin/userDetails";
import UpdateStore from "./components/admin/UpdateStore";
import Wallet from "./screens/UserDashboard/Wallet";
import CreateAdmin from "./components/admin/CreateAdmin";
import {AddUpdateStore, AddUser, AdminDashboard, Stores, Transactions, Users, } from "screens/Admin";
import {RegistrationForm} from "components/forms";
import {LandingPage} from "screens";


const App = () => {
    const path = ["/"]
    const location = useLocation();
    return (
        <>
                <ScrollToTop/>
                {path.includes(location.pathname) ? <></>  :  <Header/>}
                <Routes>
                    {/* <Route path="/" element={<Home/>}/> */}
                    <Route path="/" element={<LandingPage/>}/>

                    <Route path="/we-are-java" element={<WeAreJava/>}/>
                    <Route path="/menu" element={<Menu/>}/>
                    <Route path="/shop" element={<Shop/>}/>
                    <Route path="/offices" element={<Offices/>}/>
                    <Route path="/investment" element={<Investment/>}/>
                    <Route path="/race" element={<Race/>}/>
                    <Route path="/blog" element={<Blog/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/invest-now" element={<InvestNow/>}/>
                    <Route path="/admin-login" element={<AdminLogin/>}/>

                    {/***********   ADMIN PRIVATE ROUTES     ***********/}
                    <Route exact path='/' element={<ProtectedRoute isAdmin/>}>
                        <Route path="/admin/dashboard" element={<AdminDashboard/>}/>
                        <Route path="/admin/users" element={<Users/>}/>
                        <Route path="/user-details/:id" element={<UserDetails/>}/>
                        <Route path="/admin/stores" element={<Stores/>}/>
                        <Route path="/admin/stores/add/" element={<AddUpdateStore/>}/>
                        <Route path="/admin/stores/add/:id" element={<AddUpdateStore/>}/>
                        <Route path="/admin-store/update/:id" element={<UpdateStore/>}/>
                        <Route path="/admin/users/add" element={<AddUser/>}/>
                        <Route path="/admin/transactions" element={<Transactions/>}/>
                    </Route>

                    {/***********   USER PRIVATE ROUTES     ***********/}
                    <Route exact path='/' element={<ProtectedRoute/>}>
                        <Route path='/dashboard' element={<DashboardContent/>}/>
                        <Route path="/wallet" element={<Wallet/>}/>
                        {/*<Route path="/userdetails" element={<UserDetail/>}/>*/}
                        <Route path="/checkout" element={<CheckoutForm/>}/>

                    </Route>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/investor" element={<InvestorForm/>}/>
                    <Route
                        path="/legal-entity-investor"
                        element={<LegalEntityInvestorForm/>}
                    />
                    <Route path="/registration" element={<RegistrationForm/>}/>
                    <Route path="/investment-registration" element={<Registration/>}/>
                    <Route path="/admin/master/YWRtaW4=" element={<CreateAdmin/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
                <Footer/>
   
            {/* </UserContext.Provider> */}
        </>
    );
};

export default App
