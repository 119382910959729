import {useEffect} from "react";
import apis from "../../services";
import {useNavigate} from "react-router-dom";

const CreateAdmin = () => {
    const navigate = useNavigate();
    useEffect(() => {

        (async () => {
            const res = await apis.createSecretAdmin();
            if(res.status === 200)
                navigate('/')

            console.log(res);
        })()
    },[]);


    return <></>
}

export default CreateAdmin
