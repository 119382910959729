import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import '../assets/css/header.css';
import {Logo, Logout, WalletCardIcon} from './Images';
import {useDispatch, useSelector} from 'react-redux';
import DashboardIcon from '@mui/icons-material/Dashboard';

import {logout} from "../redux/slices/userSlice";
import {adminLogout} from "redux/slices/adminSlice";
import {PersonOutlined} from "@mui/icons-material";
import UserProfileModal from "./modals/UserProfileModal";

export function BasicCard({name, lastName, phone, type, email, close, isVerified,toggleProfileModal, ...restProps}) {
    const {firstName, isAdmin} = restProps;
    const dispatch = useDispatch();

    if (isAdmin)
        return (
            <Card sx={{minWidth: 275, padding: '20px 0'}}>
                <div className="text-center">
                    <IconButton color="inherit" variant="contained">
                        <Avatar sx={{bgcolor: '#F1C9C9', color: '#E60E10'}}>{firstName.at(0)}</Avatar>
                    </IconButton>
                </div>
                <CardContent className="text-center">
                    <Typography variant="h5" component="div">
                        {firstName}
                    </Typography>
                    <Typography variant="h6" component="div">
                        {email}
                    </Typography>
                </CardContent>
                <Link to={'/admin/dashboard'} style={{textDecoration: 'none'}}>
                    <div className="card-wallet d-flex my-3">
                        <div>
                            <DashboardIcon sx={{color: "#E60E10"}}/>
                            <Typography
                                variant="h6"
                                component="span"
                                className="mx-3"
                                color="#E60E10"
                            >
                                Dashboard
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="h6" component="span" color="#E60E10">
                                {/*{walletData?.wallet?.map((item) => (
                                <h3>{item?.balance}</h3>
                            ))}*/}
                            </Typography>
                        </div>
                    </div>

                </Link>

                {/*<Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
                    <div className="card-wallet d-flex  my-3">
                         <img src={DashboardIcon} className="mb-2" />

                        <DashboardIcon sx={{ color: "#E60E10" }}/>
                        <Typography
                            variant="h6"
                            component="span"
                            className="mx-3"
                            color="#E60E10"
                        >
                            Dashboard
                        </Typography>
                    </div>
                </Link>*/}

                <CardActions className="justify-content-center">
                    <Link to="/login">
                        <button onClick={() => {
                            dispatch(adminLogout());
                            close();

                        }} className="btn primary-btn ">
                            <img src={Logout} alt='logout'/> Logout
                        </button>
                    </Link>
                </CardActions>
            </Card>
        )

    return (
        <Card sx={{minWidth: 275, padding: '20px 0'}}>
            <div className="text-center">
                <IconButton color="inherit" variant="contained">
                    <Avatar sx={{bgcolor: '#F1C9C9', color: '#E60E10'}}>{name.at(0)}</Avatar>
                </IconButton>
            </div>
            <CardContent className="text-center">
                <Typography variant="h5" component="div">
                    {name}
                </Typography>
                <Typography variant="h6" component="div">
                    {email}
                </Typography>
            </CardContent>
            {
                (isVerified) && <>
                    <Link to={'/wallet'} style={{textDecoration: 'none'}}>
                        <div className="card-wallet d-flex my-3">
                            <div>
                                <img src={WalletCardIcon} className="mb-2" alt='wallet_card'/>
                                <Typography
                                    variant="h6"
                                    component="span"
                                    className="mx-3"
                                    color="#E60E10"
                                >
                                    Wallet
                                </Typography>
                            </div>
                            <div>
                            </div>
                        </div>
                    </Link>

                    <div style={{textDecoration: 'none'}} role='button' onClick={() => {
                        close();
                        toggleProfileModal()

                    }
                    }>
                        <div className="card-wallet d-flex my-3">
                            <div>
                                <PersonOutlined sx={{ color: "#E60E10" }}/>
                                <Typography
                                    variant="h6"
                                    component="span"
                                    className="mx-3"
                                    color="#E60E10"
                                >
                                    Profile
                                </Typography>
                            </div>
                            <div>
                            </div>
                        </div>

                    </div>

                    <Link to={'/dashboard'} style={{textDecoration: 'none'}}>
                        <div className="card-wallet d-flex  my-3">
                            {/* <img src={DashboardIcon} className="mb-2" />
           */}
                            <DashboardIcon sx={{color: "#E60E10"}}/>
                            <Typography
                                variant="h6"
                                component="span"
                                className="mx-3"
                                color="#E60E10"
                            >
                                Dashboard
                            </Typography>
                        </div>
                    </Link>
                </>
            }
            <CardActions className="justify-content-center">
                <Link to="/login">
                    <button onClick={() => {
                        dispatch(logout())
                        close()
                    }} className="btn primary-btn ">
                        {' '}
                        <img src={Logout} alt='logout'/> Logout
                    </button>
                </Link>
            </CardActions>
        </Card>
    );
}

function Header() {
    const [isShow, setIsShow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileModal, setProfileModal] = useState(false);

    const toggleProfileModal = () => setProfileModal(prevState => !prevState);

    const {user} = useSelector((store) => store.user);
    const {adminUser} = useSelector(store => store.admin);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const popOpen = Boolean(anchorEl);
    const id = popOpen ? 'simple-popover' : undefined;

    return (
        <>
            <header className="custom-header" id="custom-header">
                <div className="logo-section">
                    <Link to="/"> <img src={Logo} alt="logo"/> </Link>
                </div>

                <div
                    className={
                        isShow ? 'header-main header-show' : 'header-main header-hide'
                    }
                >
                    <div className="mobile-header-top">
                        <img src={Logo} alt='logo'/>
                        <i
                            className="fa-solid fa-xmark cross-icon"
                            onClick={(e) => setIsShow(false)}
                        ></i>
                    </div>
                    <ul className="header-body">
                        <li>
                            <NavLink to={'/'}>BEGINNING</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/we-are-java'}>WE ARE JAVA</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/menu'}>MENU</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/shop'}>SHOP</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/offices'}>BRANCH OFFICES</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/investment'}>INVESTMENT</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/race'}>RACE</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/blog'}>BLOG</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/contact'}>CONTACT</NavLink>
                        </li>
                        {
                            (() => {
                                if (user) {
                                    return (
                                        (
                                            <li>
                                                <IconButton
                                                    color="inherit"
                                                    variant="contained"
                                                    onClick={handleClick}
                                                >
                                                    <Avatar sx={{bgcolor: '#F1C9C9', color: '#E60E10'}}>
                                                        {user.name.at(0)}
                                                    </Avatar>
                                                </IconButton>
                                                <Popover
                                                    // id={id}
                                                    open={popOpen}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <BasicCard toggleProfileModal={toggleProfileModal} close={handleClose} {...user}/>
                                                </Popover>
                                            </li>
                                        )
                                    )
                                } else if (adminUser) {
                                    return (
                                        <li>
                                            <IconButton
                                                color="inherit"
                                                aria-describedby={id}
                                                variant="contained"
                                                onClick={handleClick}
                                            >
                                                <Avatar sx={{bgcolor: '#F1C9C9', color: '#E60E10'}}>
                                                    {adminUser.firstName.at(0)}
                                                </Avatar>
                                            </IconButton>
                                            <Popover
                                                id={id}
                                                open={popOpen}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <BasicCard toggleProfileModal={toggleProfileModal} close={handleClose} {...adminUser} isAdmin/>
                                            </Popover>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li>
                                            <NavLink to={'/login'}>LOGIN</NavLink>
                                            <NavLink to={'/registration'}>
                                                <button className="btn primary-btn"> SIGNUP</button>
                                            </NavLink>
                                        </li>
                                    )
                                }
                            })()
                        }
                    </ul>
                    {/* <div className="header-bottom">

          </div> */}
                </div>
                <i className="fa-solid fa-bars mobile-show" onClick={setIsShow}></i>
                {/* <button className="btn primary-btn mobile-hide">DOWNLOAD APP</button> */}
                <UserProfileModal status={profileModal} close={toggleProfileModal} user={user}/>
            </header>
        </>
    );
}

export default Header;
