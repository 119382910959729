import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import imageUrl from 'utils/imageUrl';
import './sidebar.css'

const Sidebar = ({isOpen, setIsOpen}) => {  
  const {user} = useSelector((store) => store.user);

return <>
  <div className={`sidebar ${isOpen? 'open':''}`}>
  <div className="logo-details logo-icon primary-logo">
      <div className="sb-icon-wrapper">  
      <i className='bx bxl-c-plus-plus icon'></i>
      </div>
        <img src={imageUrl('logo-icon.png')} alt=""/>
    </div>
    <div className="logo-details">
      <div className="sb-icon-wrapper">  
      <i className='bx bxl-c-plus-plus icon'></i>
      </div>
        <img src={imageUrl('bars-icon.png')} alt=""  id="btn" onClick={()=>setIsOpen(prevState => !prevState)}/>
    </div>
    <ul className="nav-list">
      {/* <li>
        <a href="#">
        <div className="sb-icon-wrapper">  
          <img src={imageUrl('rocket-icon.png')} alt="" />
      </div>
          <span className="links_name">Beginning</span>
        </a>
         <span className="tooltip">Beginning</span>
      </li>
      <li>
       <a href="#">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('hands-icon.png')} alt="" />
      </div>
         <span className="links_name">We Are Java</span>
       </a>
       <span className="tooltip">We Are Java</span>
     </li>
     <li>
       <a href="#">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('menu-icon.png')} alt="" />
      </div>
         <span className="links_name">Menu</span>
       </a>
       <span className="tooltip">Menu</span>
     </li>
     <li>
       <a href="#">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('shop-icon.png')} alt="" />
      </div>
         <span className="links_name">Shop</span>
       </a>
       <span className="tooltip">Shop</span>
     </li>
     <li>
       <a href="#">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('location-icon.png')} alt="" />
      </div>
         <span className="links_name">Branch Office</span>
       </a>
       <span className="tooltip">Branch Office</span>
     </li>
     <li>
       <a href="#">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('dollar-icon.png')} alt="" />
      </div>
         <span className="links_name">Investment</span>
       </a>
       <span className="tooltip">Investment</span>
     </li>
     <li>
       <a href="#">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('flags-icon.png')} alt="" />
      </div>
         <span className="links_name">Race</span>
       </a>
       <span className="tooltip">Race</span>
     </li>
     <li>
       <a href="#">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('b-icon.png')} alt="" />
      </div>
         <span className="links_name">Blog</span>
       </a>
       <span className="tooltip">Blog</span>
     </li>
     <li>
       <a href="#" className='call-link'>
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('call-icon.png')} alt="" />
      </div>
         <span className="links_name">Call</span>
       </a>
<<<<<<< HEAD
       <span className="tooltip">Setting</span>
     </li> */}
      <li>
       <a href="we-are-java">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('hands-icon.png')} alt="" />
      </div>
         <span className="links_name">We are java</span>
       </a>
       <span className="tooltip">We are java</span>
 </li>
     <li>
       <a href="/menu">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('menu-icon.png')} alt="" />
      </div>
         <span className="links_name">Menu</span>
       </a>
       <span className="tooltip">Menu</span>
     </li>
     <li>
       <a href="/shop">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('shop-icon.png')} alt="" />
      </div>
         <span className="links_name">Shop</span>
       </a>
       <span className="tooltip">Shop</span>
     </li>
     <li>
       <a href="/offices">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('location-icon.png')} alt="" />
      </div>
         <span className="links_name">Branch Offices</span>
       </a>
       <span className="tooltip">Branch Offices</span>
     </li>
     <li>
       <a href="/investment">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('dollar-icon.png')} alt="" />
      </div>
         <span className="links_name">Investment</span>
       </a>
       <span className="tooltip">Investment</span>
     </li>
     <li>
       <a href="/race">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('flags-icon.png')} alt="" />
      </div>
         <span className="links_name">Race</span>
       </a>
       <span className="tooltip">Race</span>
     </li>
     <li>
       <a href="/blog">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('b-icon.png')} alt="" />
      </div>
         <span className="links_name">Blog</span>
       </a>
       <span className="tooltip">Blog</span>
     </li>
     <li>
       <a href="/contact">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('call-icon.png')} alt="" />
      </div>
         <span className="links_name">Contact</span>
       </a>
       <span className="tooltip">Contact</span>
     </li>
    </ul>
  </div>

  {/* <script>
  let sidebar = document.querySelector(".sidebar");
  let closeBtn = document.querySelector("#btn");
  let searchBtn = document.querySelector(".bx-search");

  closeBtn.addEventListener("click", ()=>{
    sidebar.classList.toggle("open");
    menuBtnChange();//calling the function(optional)
  });

  searchBtn.addEventListener("click", ()=>{ // Sidebar open when you click on the search iocn
    sidebar.classList.toggle("open");
    menuBtnChange(); //calling the function(optional)
  });

  // following are the code to change sidebar button(optional)
  function menuBtnChange() {
   if(sidebar.classList.contains("open")){
     closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
   }else {
     closeBtn.classList.replace("bx-menu-alt-right","bx-menu");//replacing the iocns class
   }
  }
  </script> */}


  
  
  
  
  
  
  
  
  
  
  </>
}

export default Sidebar