
const createUtils = () => {

    const _img = (name = '') => `${process.env.PUBLIC_URL}/assets/images/${name}`;
    const isPasswordMatch = (password1, password2) => password1 === password2

    return {isPasswordMatch, _img}
};



const utils = createUtils();

export default utils;
