import {useEffect, useMemo, useState} from "react";
import {Container} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {STATIC_STORE_DATA} from "../assets/constants";
import apis from "../services";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

const CheckoutForm = () => {
    // const [prices, setPrices] = useState({ user: 0, owner: 0, total: 0 });
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const {state} = useLocation();
    const {sharePerPrice} = STATIC_STORE_DATA;

    const {user} = useSelector((store) => store.user);

    console.log(!state);

    useEffect(() => {
        if(!state) {
            navigate('/404');
        }
    },[])

    const products = state ? [
        state,
        /*{name: 'Product Name', description: 'Brief description', price: 12},
        {name: 'Product Name', description: 'Brief description', price: 12},
        {name: 'Product Name', description: 'Brief description', price: 12},
        {name: 'Product Name', description: 'Brief description', price: 12},*/
    ]: []

    /*useEffect(() => {
        calculateValues(products)
    }, []);

    const calculateValues = (products) => {
        let total = products.reduce((sum,{price}) => {
            sum+=price;
            return sum;
        },0);

        const user = total * 0.7;
        const owner = total * 0.3;
        total-= - owner;

        setPrices({user,owner,total});
    };*/

    const totalPrice = useMemo(() => {
        const storePrice = products.reduce((sum, store) => {
            sum += store?.price || 0
            return sum;
        },0);

        return storePrice * state.sharePerPrice

    },[]);

    const submitHandler = async (e) => {
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        const formData = Object.fromEntries(form);
        console.log(formData);

        setLoader(true);

        try {
            const {data} = await apis.invest(state.id, {userId: user.id, shareAmount: state.price,sharePerPrice :state.sharePerPrice,totalShares:state.totalShares});
            if(data.status){
                toast.success(data?.message)
                document.getElementById("billing-form").reset();
                navigate('/dashboard')

            }else {
                toast.error(data?.message)
            }
        }catch (e) {
            console.log(e);
            toast.error('Some error has occurred')
        }

    };
    return(
        <Container>
            <div className="row g-5 mt-2 mb-5">
                <div className="col-md-5 col-lg-4 order-md-last">
                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                        <span className="text-danger">Your cart</span>
                        <span className="badge bg-danger rounded-pill">{products.length}</span>
                    </h4>
                    <ul className="list-group mb-3">
                        {products.length > 0 && products.map(({title, description, price}) => {
                            return(
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">{title}</h6>
                                        <small className="text-muted">{description}</small>
                                    </div>
                                    <span className="text-muted">({price}) x ({state.sharePerPrice}) = ${ price * state.sharePerPrice}</span>
                                </li>
                            )
                        })}

                        {/*<li className="list-group-item d-flex justify-content-between bg-light">
                            <div className="text-success">
                                <h6 className="my-0">Promo code</h6>
                                <small>EXAMPLECODE</small>
                            </div>
                            <span className="text-success">−$5</span>
                        </li>*/}
                        <li className="list-group-item d-flex justify-content-between">
                            <span>Total (USD)</span>
                            <strong>$  {totalPrice}</strong>
                        </li>
                    </ul>

                    {/*<form className="card p-2">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Promo code"/>
                            <button type="submit" className="btn btn-secondary">Redeem</button>
                        </div>
                    </form>*/}
                </div>
                <div className="col-md-7 col-lg-8">
                    <h4 className="mb-3">Billing address</h4>
                    <form id="billing-form" className="needs-validation" onSubmit={submitHandler}>
                        <div className="row g-3">
                            <div className="col-sm-6">
                                <label htmlFor="firstName" className="form-label">First name</label>
                                <input type="text" className="form-control" id="firstName" name="firstName"
                                       required/>
                                <div className="invalid-feedback">
                                    Valid first name is required.
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <label htmlFor="lastName" className="form-label" >Last name</label>
                                <input type="text" className="form-control" id="lastName" name="lastName"
                                       required/>
                                <div className="invalid-feedback">
                                    Valid last name is required.
                                </div>
                            </div>

                            <div className="col-12">
                                <label htmlFor="email" className="form-label">Email
                                    {/*<span className="text-muted">(Optional)</span>*/}
                                </label>
                                <input type="email" className="form-control" id="email" name="email" placeholder="you@example.com" required/>
                                <div className="invalid-feedback"/>
                                Please enter a valid email address for updates.
                            </div>
                            {/*</div>*/}

                            <div className="col-12">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input type="text" className="form-control" id="address" name='address' placeholder="1234 Main St"
                                       required/>
                                <div className="invalid-feedback">
                                    Please enter your shipping address.
                                </div>
                            </div>

                            {/*<div className="col-12">
                                <label htmlFor="address2" className="form-label">Address 2 <span
                                    className="text-muted">(Optional)</span></label>
                                <input type="text" className="form-control" id="address2" placeholder="Apartment or suite"/>
                            </div>*/}

                            <div className="col-md-5">
                                <label htmlFor="country" className="form-label">Country</label>
                                <select className="form-select" id="country" name="country" required>
                                    <option value="">Choose...</option>
                                    <option>United States</option>
                                </select>
                                <div className="invalid-feedback">
                                    Please select a valid country.
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="state" className="form-label">State</label>
                                <select className="form-select" id="state" name="state" required>
                                    <option value="">Choose...</option>
                                    <option>California</option>
                                </select>
                                <div className="invalid-feedback">
                                    Please provide a valid state.
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="zip" className="form-label">Zip</label>
                                <input type="text" className="form-control" id="zip" name="state"  required/>
                                <div className="invalid-feedback">
                                    Zip code required.
                                </div>
                            </div>
                        </div>

                        <hr className="my-4"/>

                        {/*<div className="form-check">
                            <input type="checkbox" className="form-check-input" id="same-address"/>
                            <label className="form-check-label" htmlFor="same-address">Shipping address is the same
                                as my billing address</label>
                        </div>

                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="save-info"/>
                            <label className="form-check-label" htmlFor="save-info">Save this information for next
                                time</label>
                        </div>*/}

                        {/*<hr className="my-4"/>*/}

                        <h4 className="mb-3">Payment</h4>

                        <div className="my-3">
                            <div className="form-check">
                                <input id="credit" name="paymentMethod" type="radio" className="form-check-input"
                                        value="Credit Card" />
                                <label className="form-check-label" htmlFor="credit">Credit card</label>
                            </div>
                            <div className="form-check">
                                <input id="debit" name="paymentMethod" type="radio" className="form-check-input"
                                       value="Debit card"/>
                                <label className="form-check-label" htmlFor="debit">Debit card</label>
                            </div>
                            <div className="form-check">
                                <input id="paypal" name="paymentMethod" type="radio" className="form-check-input"
                                       value="PayPal"/>
                                <label className="form-check-label" htmlFor="paypal">PayPal</label>
                            </div>
                        </div>

                        <div className="row gy-3">
                            <div className="col-md-6">
                                <label htmlFor="cc-name" className="form-label">Name on card</label>
                                <input type="text" className="form-control" id="cc-name" name="cc-name" required/>
                                <small className="text-muted">Full name as displayed on card</small>
                                <div className="invalid-feedback">
                                    Name on card is required
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="cc-number" className="form-label">Credit card number</label>
                                <input type="text" className="form-control" id="cc-number" name="cc-number" required/>
                                <div className="invalid-feedback">
                                    Credit card number is required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="cc-expiration" className="form-label">Expiration</label>
                                <input type="text" className="form-control" id="cc-expiration" name="cc-expiration" required/>
                                <div className="invalid-feedback">
                                    Expiration date required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="cc-cvv" className="form-label">CVV</label>
                                <input type="text" className="form-control" id="cc-cvv" name="cc-cvv" required/>
                                <div className="invalid-feedback">
                                    Security code required
                                </div>
                            </div>
                        </div>

                        <hr className="my-4"/>

                        <button className="w-100 btn btn-danger btn-lg" type="submit">Continue to checkout
                        </button>
                    </form>
                </div>
            </div>
        </Container>
    )
}


export default CheckoutForm
