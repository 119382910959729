import {Box, TextField} from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Button, Container, Modal} from "react-bootstrap";
import {memo, useRef, useState} from "react";
import apis from "../services";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
// import { getWallet } from "../redux/slices/walletSlicer";


const EntityModal = memo((props) => {
    const {status, toggleModal,_id: id,sharePerPrice,totalShares, getStores} = props;
    
    //const {sharePerPrice, totalShares} = STATIC_STORE_DATA;

    const navigate = useNavigate();
    const shareAmountRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const {user} = useSelector((store) => store.user);
    const [shareAmount, setShareAmount] = useState(0);


    const saveDataAPI = async () => {
        setLoading(true);

        if(totalShares <= 0) {
            setLoading(false);
            return toast.warning("Shares are not available for this store");
        }

        if(shareAmount <= 0) {
            setLoading(false);
            return toast.warning("Number of shares must be 1 or greater");
        }

        const body = {userId: user.id, shareAmount ,sharePerPrice,totalShares}
        try {
            const {data} = await apis.invest(id, body);
            console.log(data.status);
            if(data.status){
                await getStores()
                toast.success(data.message)
                toggleModal()
            }else {
                toast.warn(data.message)
            }
            setLoading(false);
        }catch (e) {
            console.log('Error', e);
            toast.error(e.message);
            setLoading(false);
        }
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        if(shareAmount <= 0){
            return toast.warn(`Purchase share number can\'t be ${shareAmount}`);
        }

        if(shareAmount > totalShares) {
            return toast.warn(`you cannot purchase ${shareAmount}, total available shares are: ${totalShares}`)
        }

        const {id: _id, title, description, price = shareAmount} = props;

        navigate('/checkout',{state: {title,description,price, id,sharePerPrice,totalShares}})
    }

    return (
        <Container>
            <Box component="form" sx={{p: 5}} onSubmit={onSubmitHandler}>
                <Modal show={status} onHide={toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Investment Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Total Shares in Pool : {totalShares}{" "}
                                    </TableCell>
                                    <TableCell>
                                        Per Price Share : {sharePerPrice}{" "}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <TableCell aria-label="a dense table">
                            How many shares you want to purchase?
                        </TableCell>

                        <TextField
                            inputRef={shareAmountRef}
                            id="shareAmount"
                            amount="shareAmount"
                            type='number'
                            fullWidth
                            margin="normal"
                            label="Share"
                            required
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={(e) => setShareAmount(e.target.value)}
                        />
                        <h3>
                            Total Investment : ${sharePerPrice * shareAmount}
                        </h3>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={toggleModal} variant="dark">
                            Cancel
                        </Button>
                        <Button onClick={onSubmitHandler} variant="primary" disabled={loading}>
                            {loading ? 'in-process' : 'Proceed'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Box>
        </Container>
    );
});

export default EntityModal;
