const TransactionTable = (transactions = []) => {

    const columns = [
        {field: 'id', headerName: 'Id', width: 150, hide: true,},
        {field: 'store', headerName: 'Store', flex: 1},
        {field: 'userName', headerName: 'User', flex: 1},
        {field: 'amountInvested', headerName: 'Invested Amount', flex: 1},
        {field: 'sharePrice', headerName: 'Share Price', flex: 1},
        {field: 'purchasedShare', headerName: 'Purchased Shares', flex: 1}
    ];

    const rows = transactions.length === 0
        ? []
        : [
            ...transactions.map((transaction,index) => ({
                id: index,
                store: transaction['storeName'],
                userName: transaction['userName'],
                amountInvested: transaction['amountInvested'],
                sharePrice: transaction['sharePrice'],
                purchasedShare: new Date(transaction['purchasedAt']).toLocaleString()
            }))
        ];


    return {rows, columns}
}

export default TransactionTable
