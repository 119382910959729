import {Col, Container, Form, Row} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import InvestorForm from '../components/forms/InvestorForm';
import LegalEntityForm from '../components/forms/LegalEntityForm';
import {FORM_NAMES} from '../assets/constants';
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Registration = () => {
    const navigate = useNavigate();
    const {search, pathname} = useLocation();

    const searchParams = new URLSearchParams(search);
    const {type} = Object.fromEntries(searchParams)

    const [formName, setFormName] = useState(type);

    const {user} = useSelector(store => store.user);

    useEffect(() =>{
        if(!user){
            navigate('/login', {state: {from: pathname}})
        }
    },[user])

    const onFormChangeHandler = (e) => setFormName(e.target.value);

    const renderForm = (form) => {
        if (form === FORM_NAMES.investor) return <InvestorForm/>
        else if (form === FORM_NAMES.legalEntity) return <LegalEntityForm/>
        else return <LegalEntityForm/>
    }

    return (
        <Container>
            <Row>
                <Col md={4}/>
                <Col md={4}>
                    <Form.Group className="mb-3 custom-select">
                        <Form.Select id="formName" onChange={onFormChangeHandler} value={formName}>
                            {Object.entries(FORM_NAMES).map(([key, name], index) => (
                                <option key={index} value={key} disabled={name === 'JTC Employee Form'}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                {renderForm(FORM_NAMES[formName])}
            </Row>
        </Container>
    );
};

export default Registration;
