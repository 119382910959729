import "../assets/css/footer.css";
import React, { useState } from "react";
import { Fade, Flash } from "react-reveal/";
import { Container } from "react-bootstrap";

function Footer() {
  const [email, setEmail] = useState("");

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setEmail("");
  };

  return (
    <>
        <div className="footer-wrapper">
        <Container>
          <footer className="red-bg">
            <h3 className="text-white text-uppercase">
              Subscribe to receive our news
            </h3>
            <div className="join-section">
              <form onSubmit={onSubmitHandler}>
                <input
                  id="custom"
                  className="custom-input"
                  placeholder="Enter Email Address"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button className="btn secondary-btn">
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
              </form>
            </div>
            <div className="footer-social">
              <h3 className="common-heading text-white fw-light">Follow us</h3>
              <ul>
                <li>
                  <a href="#" target="_blank">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="last-section">
              <h2 className="fw-light">Java Times Caffe</h2>
              <p>©2022 JAVA TIMES CAFÉ. ALL RIGHTS RESERVED. </p>
            </div>
          </footer>
        </Container>
        </div>
    </>
  );
}

export default Footer;
