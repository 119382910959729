import {useEffect, useState} from "react";
import reduxApis from "redux/apis";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Alert, Button, Col, Container, Form, FormText, Row} from "react-bootstrap";

const Login = () => {
    const [formData, setFormData] = useState({email: "", password: ""});

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {state} = useLocation()

    const {user, loading} = useSelector((store) => store.user);

    if (user) {
        navigate(state ? state.from : '/dashboard')
    }

useEffect(()=>{
    if(user)
    {
        navigate('/')
    }
},[])
    const onChangeHandler = (e) => {
        const {id, value} = e.target;
        setFormData((prevState) => ({...prevState, [id]: value}));
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            await dispatch(reduxApis.userLogin(formData))
        } catch (error) {
            console.log('Error in Login', error);
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h2 className="text-center text-uppercase my-5">Login User</h2>
                </Col>
            </Row>
            <Form onSubmit={onSubmitHandler}>
                <Row>
                    <Col md={4}/>
                    <Col md={4}>
                        {/*{error.status && (
                            <Alert
                                variant={error.variant}
                                onClose={() =>
                                    setError((prevState) => ({...prevState, status: false}))
                                }
                                dismissible
                            >
                                Password Invalid
                            </Alert>
                        )}*/}

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                onChange={onChangeHandler}
                                value={formData.email}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={onChangeHandler}
                                value={formData.password}
                                required
                            />
                            <FormText>
                                <Link to="/admin-login">Login as a admin ?</Link>
                            </FormText>
                        </Form.Group>

                        <Button
                            variant="outline-danger"
                            className="w-100 mb-5"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? 'Logging in' : 'Log in'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default Login;
