import React, {Fragment, useState} from "react";
import utils from "utils";
import apis from "services";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";

const RegistrationForm = () => {
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const [togglePassword, setTogglePassword] = useState({password: false, confirmPassword: false});

    const toggle = (key) =>
        setTogglePassword((prevState) => ({...prevState, [key]: !prevState[key]}));

    const submitHandler = async (e) => {
        e.preventDefault();

        const form = new FormData(e.currentTarget);
        const formData = Object.fromEntries(form);

        const {password, confirmPassword} = formData;

        if (!utils.isPasswordMatch(password, confirmPassword)) {
            return toast.error('Password & Confirm Password field does not match')
        }

        try {
            setLoader(true);
            const response = await apis.registration(formData);
            if (response.status === 201) {
                toast.success(response.data.message);
                setTimeout(() => navigate("/Login"), 2000);
            }
        } catch (e) {
            console.error(e);
            toast.error(e.message)
            setLoader(false);
        }


        // console.log(formData);

    }

    return (
        <Fragment>
            <Form onSubmit={submitHandler} className='my-5'>
                <Row className="my-3">
                    <Col>
                        <h2 className="text-center text-uppercase">User Registration</h2>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center align-items-center'>
                    <Col md={6}>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control type="text" name='name' placeholder='John smith' required/>
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name='email' placeholder='jtc@gmail.com' required/>
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="phoneNo">
                                    <Form.Label>Phone Number</Form.Label>
                                    <PhoneInput
                                        country="mx"
                                        inputProps={{name: 'phone'}}
                                        inputStyle={{width: "100%"}}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control type={togglePassword.password ? "text" : 'password'}
                                                      name='password' placeholder='Password Here...' required/>
                                        <InputGroup.Text className='cursor-pointer' role='button'
                                                         onClick={() => toggle('password')}>
                                            {togglePassword.password
                                                ? <VisibilityOff/>
                                                : <Visibility/>
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="confirmPassword">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control type={togglePassword.confirmPassword ? "text" : 'password'}
                                                      name='confirmPassword'
                                                      placeholder='Confirm Password Here...' required/>
                                        <InputGroup.Text className='cursor-pointer' role='button'
                                                         onClick={() => toggle('confirmPassword')}>
                                            {togglePassword.confirmPassword
                                                ? <VisibilityOff/>
                                                : <Visibility/>
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Button className='w-100' variant="outline-danger"
                                    type='submit' disabled={loader}> {loader ? 'Submitting' : 'Submit'}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}


export default RegistrationForm;
