import {useNavigate} from "react-router-dom";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {USERS_TYPES} from "../../assets/constants";

const InvestOptionsModal = ({status, toggleModal}) => {
    const navigate = useNavigate();

    const investorHandler = () => navigate(`/investment-registration?type=investor`);
    const legalEntityInvestor = () => navigate('/investment-registration?type=legalEntity');

    return (<Modal show={status} onHide={toggleModal} centered>
        <Modal.Header closeButton>
            <Modal.Title>Investment Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col>
                    Please Select the Investment Option:
                </Col>
                <Col xs={12} className='d-flex justify-content-around my-2 gap-2'>
                    <Button variant="outline-primary" onClick={investorHandler}>
                        Investor
                    </Button>
                    <Button variant="outline-danger" onClick={legalEntityInvestor}>
                        Legal Entity Investor
                    </Button>
                    <Button variant="outline-dark" onClick={toggleModal} disabled>
                        JTC Employee Investor
                    </Button>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>)
}

export default InvestOptionsModal
