import apis from "services";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setUser} from "redux/slices/userSlice";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";


const UserProfileModal = ({status, close}) => {
    const dispatch = useDispatch();

    const [userData, setUserData] = useState(null);
    const [readOnly, setReadOnly] = useState(true);

    const {user} = useSelector((store) =>store.user);

    useEffect(() => {
        if(!userData) {
            setUserData(user);
        }
    },[user, userData])



    const toggleButton = async () => {
        if (readOnly) {
            setReadOnly(false);
        } else {
            console.log(userData);
            const res = await apis.updateUserProfile(user.id, userData);
            if (res.data.status) {
                toast.success(res.data.message);
                dispatch(setUser(res.data.user))
                setReadOnly(true);
            }
        }
    }

    const onChangeHandler = (event) => {
        const {id, value} = event.target;

        setUserData({...userData, [id]: value});
    }

    return (
        <Dialog open={status} onClose={close} maxWidth='md'>
            <DialogTitle> Edit Profile </DialogTitle>
            <DialogContent>
                <Grid container direction='column' gap={2} my={2}>
                    <Grid item>
                        <TextField
                            id="name"
                            value={userData?.name || ''}
                            onChange={onChangeHandler}
                            placeholder={"name"}
                            label={"name"}
                            disabled={readOnly}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="lastName"
                            value={userData?.lastName || ''}
                            onChange={onChangeHandler}
                            label={"lastName"}
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="phone"
                            value={userData?.phone || ''}
                            onChange={onChangeHandler}
                            label="phone"
                            disabled={readOnly}
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Grid item container gap={1}>
                        <Button
                            onClick={toggleButton}
                            variant="outlined"
                            style={{borderColor: "#FF0000", color: '#FF0000'}}
                            fullWidth
                        >
                            {readOnly ? 'Edit' : 'Update'}
                        </Button>
                        <Button
                            onClick={close}
                            style={{backgroundColor: "#FF0000", color: "#ffff"}}
                            fullWidth>Close
                        </Button>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}


export default UserProfileModal;
