import {Box, Container, Grid, Tab, Tabs, TextField, Typography} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "../../components/admin/AdminSidebar";
import {Fragment, useState} from "react";
import {FORM_NAMES} from "../../assets/constants";
import InvestorForm from "../../components/forms/InvestorForm";
import LegalEntityForm from "../../components/forms/LegalEntityForm";
// import InvestorForm from "../InvestorForm";


const TabPanel = ({children, value, index, ...rest}) =>
    value === index
        ? (<Fragment>{children}</Fragment>)
        : null

/*function TabPanel({children, value, index, ...rest}) {


    return (

        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}*/

const AddUser = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => setValue(newValue);
    return (
        <>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <AdminSidebar/>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        // height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Box display="flex" justifyContent="center" sx={{backgroundColor: 'var(--primary)', padding: 1}}>
                        <Typography variant="h5" component="div" sx={{fontWeight: 'bold', color: 'white'}}>
                            Add User
                        </Typography>
                    </Box>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered
                              textColor="secondary"
                              indicatorColor="secondary"
                        >
                            {Object.values(FORM_NAMES).map((name, index) => <Tab key={index} label={name} disabled={name === FORM_NAMES.jtc}/>)}
                        </Tabs>
                    </Box>
                    <Container>
                        <Grid container my={2}>
                            <TabPanel value={value} index={0}>
                                <InvestorForm isCreatedByAdmin/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <LegalEntityForm/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                JTC Employee
                            </TabPanel>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    )
};


export default AddUser
