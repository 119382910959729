import React, {Fragment, useEffect, useState} from "react";
import {Link as LinkR} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import MailIcon from "@mui/icons-material/Mail";
import {useDispatch} from "react-redux";
import {Profit,} from "../../components/Images";
import Chart from "../../dashboard/Chart";
import apis from "services";
import AdminSidebar from "../../components/admin/AdminSidebar";
import {Paid, PaidOutlined} from "@mui/icons-material";


export const mainListItems = (
    <Fragment>
        <LinkR to={"/admin/dashboard"} style={{textDecoration: 'none'}}>
            <ListItemButton
                // autoFocus={true}
                className="justify-content-center mt-3 active"
            >
                <ListItemIcon>
                    <DashboardIcon sx={{color: "#fff"}}/>
                </ListItemIcon>
                <ListItemText className="text-white" primary="Dashboard"/>
            </ListItemButton>
        </LinkR>

        <LinkR to={"/admin/users"} style={{textDecoration: 'none'}}>
            <ListItemButton className="justify-content-center mt-3">

                <ListItemIcon>
                    <PeopleIcon sx={{color: "#fff"}}/>
                </ListItemIcon>
                <ListItemText className="text-white" primary="Users"/>
            </ListItemButton>
        </LinkR>

        <LinkR to={"/admin/stores"} style={{textDecoration: 'none'}}>
            <ListItemButton className="justify-content-center mt-3">

                <ListItemIcon sx={{color: "#fff"}}>
                    <MailIcon/>
                </ListItemIcon>
                <ListItemText className="text-white" primary="Stores"/>
            </ListItemButton>
        </LinkR>

        <LinkR to={"/admin/transactions"} style={{textDecoration: 'none'}}>
            <ListItemButton className="justify-content-center mt-3">

                <ListItemIcon sx={{color: "#fff"}}>
                    <Paid/>
                </ListItemIcon>
                <ListItemText className="text-white" primary="Transactions"/>
            </ListItemButton>
        </LinkR>
    </Fragment>
);

const mdTheme = createTheme();

const AdminDashboard = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [TotalUsers, setTotalUsers] = useState("loading ...");
    // let { paramId } = useParams();
    // if (paramId === "dashboard") {
    //   let header = (document.getElementById("custom-header").style.display =
    //     "none");
    // }

    const dispatch = useDispatch();

    const [totalUsersCount, setTotalUsersCount] = useState(0);
    const [totalStoresCount, setTotalStoresCount] = useState(0);

    const fetchTotalStoresCountCount = async () => {
        const res = await apis.getTotalStoresCount();
        if (res?.data?.status) setTotalStoresCount(res?.data?.totalStores)
    }

    const fetchTotalUsersCount = async () => {
        const res = await apis.getTotalUsersCount();
        if (res?.data?.status) setTotalUsersCount(res?.data?.totalUsersCount)
    }

    useEffect(() => {
        //Promise All for simultaneous requests
        (async () => {
            await Promise.all([fetchTotalStoresCountCount(), fetchTotalUsersCount()])
        })()
    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <AdminSidebar/>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >

                    <Toolbar/>
                    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                        <Grid container spacing={3}>
                            {/* small boxes */}
                            <Grid item xs={4} md={4} lg={4}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    className="dashboard-box"
                                >
                                    <h3 className="text-center">Total Users</h3>
                                    <h4 className="text-center">{totalUsersCount}</h4>
                                </Paper>
                            </Grid>
                            {/* new */}
                            <Grid item xs={4} md={4} lg={4}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    className="dashboard-box"
                                >
                                    <h3 className="text-center">Total Shares</h3>
                                    <h4 className="text-center">200</h4>
                                </Paper>
                            </Grid>

                            <Grid item xs={4} md={4} lg={4}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    className="dashboard-box"
                                >
                                    <h3 className="text-center">Available Shares</h3>
                                    <h4 className="text-center">0</h4>
                                </Paper>
                            </Grid>


                            <Grid item xs={4} md={4} lg={4}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    className="dashboard-box"
                                >

                                    <h3 className="text-center">Total Stores</h3>
                                    <h4 className="text-center">{totalStoresCount}</h4>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    className="dashboard-box"
                                >
                                    <img src={Profit} alt="Profit.png"/>

                                    <h4 className="text-center">0</h4>
                                </Paper>
                            </Grid>
                            {/* Chart */}
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        height: 240,
                                    }}
                                >
                                    <Chart/>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default AdminDashboard
