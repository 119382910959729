import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {CardOne} from "../components/Images";
import {Bounce, Flip} from "react-reveal";
import apis from "../services";
import {toast} from "react-toastify";

const INITIAL_FORM_STATE = {name: '', email: '', message: ''};

function Contact() {
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState(INITIAL_FORM_STATE);

    const onChangeHandler = (event) => {
        const {name, value} = event.target;

        setFormData(prevState => ({...prevState, [name]: value}))
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();

        try {
            setLoader(true);
            const res = await apis.saveContact(formData);

            if (res.status === 201) {
                toast.success(res.data.message);
                setFormData(INITIAL_FORM_STATE)
            }

            setLoader(false);
        } catch (e) {
            toast.error('')
            setLoader(false)
        }
    }

    return (
        <>
            <section>
                <Container>
                    <div className="first-section-with-title">
                        <Bounce bottom cascade>
                            <h3 className="common-heading">Contact Us</h3>
                        </Bounce>
                    </div>
                    <form onSubmit={onSubmitHandler}>
                        <Row className="mb-5">
                            <Col lg={6}>
                                <h3 className="form-heading text-center">
                                    We want to be in contact with you.
                                </h3>
                                <div className="contact-form">
                                    <input className="form-control" placeholder="Name" name='name'
                                           onChange={onChangeHandler} value={formData.name} required/>
                                    <input className="form-control" type='email' placeholder="E-Mail" name='email'
                                           onChange={onChangeHandler} value={formData.email} required/>
                                    <textarea
                                        className="form-control"
                                        placeholder="Write your message here..."
                                        name='message'
                                        onChange={onChangeHandler}
                                        value={formData.message}
                                        rows={6}
                                    ></textarea>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <Flip right>
                                    <div className="text-center">
                                        <img src={CardOne} className="contact-image"/>
                                    </div>
                                </Flip>
                            </Col>
                            <Col lg={6} className='text-center'>
                                <button className="btn primary-btn w-25" type='submit' disabled={loader}>
                                    {loader ? 'Submitting' : 'Submit'}
                                </button>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </section>
        </>
    );
}

export default Contact;
