import React, {useState} from 'react';
import MuiDrawer from '@mui/material/Drawer';
import {useSelector} from 'react-redux';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import {Box, Container, Divider, Grid, IconButton, List, Paper, Toolbar} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Sidebar from "../../dashboard/Sidebar";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: '#E21C21',
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
const mdTheme = createTheme();

const Wallet = () => {
    const [open, setOpen] = useState(true);
    const user = useSelector((store) => store.user);
    const {wallet} = useSelector((store) => store.wallet);
    const storeData = useSelector((store) => store.store);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{display: 'flex'}}>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton className="chevron-icon">
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        <Sidebar/>
                        <Divider sx={{my: 1}}/>
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <div>
                        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                            <Grid container spacing={3}>
                                {/* small boxes */}
                                <Grid item xs={4} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                        className="dashboard-box"
                                    >
                                        <h4 className="text-center">
                                            Share Value: <h4>{wallet?.amount}</h4>
                                        </h4>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                        className="dashboard-box"
                                    >
                                        <h4 className="text-center">
                                            Share Amount:<h4> {wallet?.shares}</h4>
                                        </h4>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                        className="dashboard-box"
                                    >
                                        <h4 className="text-center">
                                            Total Share: <h4> {wallet?.dividend}</h4>
                                        </h4>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                        className="dashboard-box"
                                    >
                                        <h4 className="text-center">
                                            Dividend Total:
                                            <h4>{storeData?.store?.dividend}</h4>
                                        </h4>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>

                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default Wallet
