import React, { useEffect, useState } from "react";
import {Fade} from "react-reveal";
import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LocationImg} from "../components/Images";
import apis from "../services/index" 
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from "../utils";
import { setUser } from "redux/slices/userSlice";

function InvestCard(props) {
    const {toggleModal, name, title, location: {address}, description, _id, image, toggleInvestmentModal} = props;
    let dispatch = useDispatch();
    const {user} = useSelector((store) => store.user);
    
      const [data,setUserData] = useState();

const fetchUser = async()=>{
    const res = await apis.getProfile(user?.id);
    if (res.data.status)
        setUserData(res.data.user)
        dispatch(setUser({id :res.data.user._id , ...res.data.user}))
} 

    useEffect(() => {
        (async () => {
                await fetchUser()
        })();
    }, []);

    const handleSubmit = () => {
        if (!user)
        {
            return toast.info("Register yourself first to invest")
        }
        else if(data?.isRequested === true &&  data?.isVerified === false)
        {
            return toast.info("Your status of investment is pending")
        }
        else if(data?.isVerified){
        toggleModal(_id);
        }
        else {
            toggleInvestmentModal();
        }
    };

    return (
        <>
            <Col lg={3} md={3}>
                <Fade bottom>
                    <div className="invest-now-card shop-item">
                        <Link to={"#"}>
                            <div>
                                <div className="image-section">
                                    <figure>
                                        <img src={image} alt={name}
                                             onError={(e) => e.currentTarget.src = utils._img('404.png')} width={350} height={360}/>
                                        <figcaption>
                                            <img alt="" src={LocationImg} className="location-icon"/>
                                            <span>{address}</span>
                                        </figcaption>
                                    </figure>
                                </div>
                                <h4>{title}</h4>
                                <p>{description}</p>
                                <button className="btn primary-btn" onClick={handleSubmit}>Invest Now</button>
                            </div>
                        </Link>
                    </div>
                </Fade>
            </Col>
        </>
    );
}

export default InvestCard;
